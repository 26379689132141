<template>
    <div>
      <b-modal
        id="modal-form"
        ok-only
        centered
        size="md"
        content-class="shadow"
        hide-footer
      >
      <template #modal-title> <div style="font-size:24px; font-weight:600;">{{ createMode ? 'Tambah' : 'Ubah'}} Pengguna</div></template>
        <div >
          <b-form-row>
            <b-col>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Nama Pengguna<span class="text-danger">*</span></p>
                </b-col>:<b-col sm="7">
                  <b-form-input type="text" v-model="form.nama_pengguna">
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">NIP<span class="text-danger">*</span></p>
                </b-col>:<b-col sm="7">
                  <b-form-input type="text" v-model="form.nip">
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">NIK<span class="text-danger">*</span></p>
                </b-col>:<b-col sm="7">
                  <b-form-input type="text" v-model="form.nik">
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Email Pengguna<span class="text-danger">*</span></p>
                </b-col>:<b-col sm="7">
                  <b-form-input type="text" v-model="form.email">
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">No HP<span class="text-danger">*</span></p>
                </b-col>:<b-col sm="7">
                  <b-form-input type="text" v-model="form.no_hp">
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Instansi<span class="text-danger">*</span></p>
                </b-col>:<b-col sm="7">
                  <model-select :options="options"
                      v-model="form.instansi"
                      placeholder="select item">
                  </model-select>
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">User Type<span class="text-danger">*</span></p>
                </b-col>:<b-col sm="7">
                  <b-form-select style="max-width: 478px;" v-model="form.user_type">
                    <b-form-select-option value="" disabled>Silahkan Pilih Role</b-form-select-option>
                    <b-form-select-option :value="item.id" v-for="(item,index) in listRole" :key="index">{{item.role_name}}</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Unggah Foto</p>
                </b-col>:<b-col sm="7">
                  <input style="display: none;" @change="uploadFile" type="file" :accept="'image/jpg,image/jpeg,image/png'" ref="fileInput"/>
                  <b-button :disabled="loadUpload" size="sm" class="mr-3" variant="primary" @click="fileClicked()">
                    <v-icon v-if="!loadUpload" style="color:white;" small>mdi-file-plus</v-icon>
                    
                    {{loadUpload ? 'Sedang Upload' : 'Unggah'}}
                  </b-button>
                </b-col>
              </b-row>             
            </b-col>
          </b-form-row>
          <div class="text-center">
            <b-button
              variant="outline-primary"
              class="mt-16 text-center mr-5"
              @click="closeForm()"
            >
              Kembali
            </b-button>
            
            <b-button
              variant="primary"
              class="mt-16 text-center"
              @click="submit()"
            >
              {{ createMode ? 'Tambah' : 'Ubah'}} Pengguna
            </b-button>
          </div>
        </div>
      </b-modal>
      
      <b-modal
        id="formKonfirmasi"
        ok-only
        centered
        size="md"
        content-class="shadow"
        hide-header
        hide-footer
      >
        <div>
          <b-container>
            <p style="font-size:18px; margin-top:20px;">
              Apakah anda yakin ingin Menghapus Pengguna?
            </p>
          </b-container>
          <div class="text-center">
            <b-button
              variant="outline-secondary"
              class="mt-16 text-center mr-5"
              @click="$bvModal.hide('formKonfirmasi')"
            >
              Tidak
            </b-button>
            <b-button
              variant="danger"
              class="mt-16 text-center"
              @click="deleteData()"
            >
              Yakin
            </b-button>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="change-password-form"
        ok-only
        centered
        size="md"
        content-class="shadow"
        hide-footer
      >
      <template #modal-title> <div style="font-size:24px; font-weight:600;">Ganti Kata Sandi Pengguna</div></template>
        <div >
          <b-form-row>
            <b-col>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="12">
                  <p style="font-weight: 600; margin:0px;">Kata Sandi Baru</p>
                </b-col><b-col sm="12">
                  <b-form-input type="password" v-model="formPass.newPassword">
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="12">
                  <p style="font-weight: 600; margin:0px;">Konfirmasi Kata Sandi</p>
                </b-col><b-col sm="12">
                  <b-form-input type="password" v-model="formPass.confirmPassword">
                  </b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-form-row>
          <div class="text-center">
            <b-button
              variant="outline-primary"
              class="mt-16 text-center mr-5"
              @click="$bvModal.hide('change-password-form')"
            >
              Kembali
            </b-button>
            <b-button
              variant="primary"
              class="mt-16 text-center"
              @click="submitPassword()"
            >
              Ganti
            </b-button>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="detail-pengguna"
        ok-only
        centered
        size="xl"
        content-class="shadow"
        hide-footer
        hide-header
      >
      <b-container fluid class="mb-5 mt-0 pt-0">
        <b-row class="pr-0 pl-0">
          <b-col class="d-flex justify-content-end position-relative pr-0 pl-0">
            <b style="font-size:20px;position:absolute; left:40%;">
              Detail Pengguna
            </b>
            <div>
              <b-button variant="primary" size="sm" class="mr-3" @click="changePassword()">
                <v-icon>mdi-lock</v-icon>
                Ganti Kata Sandi
              </b-button>
              <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4" @click="$bvModal.hide('detail-pengguna')">
                <v-icon class="pr-3">mdi-close</v-icon>
                Tutup
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
        <b-row v-if="detailPengguna !== ''">
          <b-col cols="2">
            <img v-if="detailPengguna.profilUser.file_foto === null || detailPengguna.profilUser.file_foto === ''"
              src="/assets/images/default-thumbnail.jpg"
              style="width: 100%;"
            >
            <img
            v-else
            @error="replaceByDefault"
              :src="fileURL + '/' + detailPengguna.profilUser.file_foto"
              style="width: 100%;"
              alt="abstract"
            />
          </b-col>
          <b-col cols="9">
            <b-row class="pb-3 align-items-center">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">Nama Pengguna</p></b-col
              >:<b-col sm="8">
                {{ detailPengguna.profilUser.nama_lengkap}}
              </b-col>
            </b-row>
            <b-row class="pb-3 align-items-center">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">NIP</p></b-col
              >:<b-col sm="8">
                {{ detailPengguna.username}}
              </b-col>
            </b-row>
            <b-row class="pb-3 align-items-center">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">NIK</p></b-col
              >:<b-col sm="8">
                {{ detailPengguna.profilUser.identitas}}
              </b-col>
            </b-row>
            <b-row class="pb-3 align-items-center">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">Email Pengguna</p></b-col
              >:<b-col sm="8">
                {{ detailPengguna.profilUser.email}}
              </b-col>
            </b-row>
            <b-row class="pb-3 align-items-center">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">No HP</p></b-col
              >:<b-col sm="8">
                {{ detailPengguna.profilUser.no_telp}}
              </b-col>
            </b-row>
            <b-row class="pb-3">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">Instansi</p></b-col
              >:<b-col sm="8">
                {{ detailPengguna.profilUser.masterKesbangpol ? detailPengguna.profilUser.masterKesbangpol.nama : '-'}}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div
            class="table-responsive mt-4"
            style="border-radius:15px;"
          >
            <table
              class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          "
              style="font-size:14px !important;"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Aktivitas Pengguna</th>
                  <th style="min-width: 50px" class="text-capitalize">Alamat IP</th>
                  <th style="min-width: 50px" class="text-capitalize">Tanggal</th>
                </tr>
              </thead>
              <tbody v-if="loadingAct && errAct == true">
                  <template>
                      <tr>
                          <td colspan="6"> Sedang memuat... </td>
                      </tr>
                  </template>
              </tbody>
              <tbody v-if="!loadingAct && listActivity.length == 0 && errAct == false">
                  <template>
                      <tr>
                          <td colspan="6"> Belum ada aktivitas...</td>
                      </tr>
                  </template>
              </tbody>
              <tbody v-if="!loadingAct && errAct == true">
                  <template>
                      <tr>
                          <td colspan="6"> Gagal memuat data...</td>
                      </tr>
                  </template>
              </tbody>
              <tbody v-if="!loadingAct" style="background: #FFFFFF;">
                <template v-for="(item, i) in listActivity">
                  <tr v-bind:key="i">
                    <td >
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ (i+1) + ((currentPageModal-1)*20)}}</span>
                    </td>
                    <!-- <td >
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ i + 1}}</span>
                    </td> -->
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{item.activity}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ item.ip_address}}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.activity_date | moment('DD-MM-YYYY HH:mm:ss')}}</span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
                <b-col class="d-flex justify-content-end">
                  <b-pagination
                    v-model="currentPageModal"
                    :total-rows="20"
                    :per-page="20"
                    aria-controls="my-table"
                  ></b-pagination>
                </b-col>
              </b-row>
      </b-modal>


      <b-row>
        <b-col cols="12">
          <!-- <b-card class="shadow" id="search-card"> -->
            <b-row>
            <b-col cols="12">
              <!-- <div class="form-row"> -->
                <div class="d-flex justify-content-between align-items-center p-1 shadow" style="background:white; border-radius:10px;">
                  <input
                    type="text"
                    id="search"
                    v-model="search"
                    class="form-control border-0"
                    @keyup.enter="searching()"
                    :placeholder="'Cari...'"
                  />
                  <b-button
                    size="sm"
                    style="width:80px;margin-right:10px;"
                    variant="primary"
                    @click="searching()"
                  >
                    <v-icon
                      style="color:white;"
                      small
                    >mdi-magnify</v-icon>
                    Cari
                  </b-button>
                </div>
              <!-- </div> -->
            </b-col>
          </b-row>
          <!-- </b-card> -->
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col class="d-flex align-items-center justify-content-between">
          <span style="font-size:16px; font-weight:bold;">Tambah Pengguna</span>
          <div>
              <b-button
              size="sm"
              variant="primary"
              @click="addForm()"
            >
              <v-icon
                style="color:white;"
                small
              >mdi-plus-box</v-icon>
              Tambah Pengguna
            </b-button>
          </div>
        </b-col>
      </b-row>
       <div
            class="table-responsive mt-4"
            style="border-radius:15px;"
          >
            <table
              class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          "
              style="font-size:14px !important;"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Nama Pengguna</th>
                  <th style="min-width: 50px" class="text-capitalize">NIP</th>
                  <th style="min-width: 50px" class="text-capitalize">Instansi</th>
                  <th style="min-width: 50px" class="text-capitalize">Status</th>
                  <th style="width: 100px" class="text-capitalize">Terakhir Masuk</th>
                  <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <!-- <tbody v-if="list.length == 0">
                  <template>
                      <tr>
                          <td colspan="6"> -- Tidak ada permohonan -- </td>
                      </tr>
                  </template>
              </tbody> -->
              <tbody style="background: #FFFFFF;">
                <template v-for="(item, i) in list">
                  <tr v-bind:key="i">
                    <td >
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ (i+1) + ((currentPage-1)*10)}}</span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{ item.nama_lengkap}}
                    <!-- {{ item.profilUser ? item.profilUser.nama_lengkap : item.username}} -->
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ item.user.username}}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.masterKesbangpol ? item.masterKesbangpol.nama : '-'}}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">
                    {{ item.user.status == 1 ? 'Aktif' : 'Tidak Aktif'}}
                    </span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">
                      {{ item.user.last_login | moment('DD-MM-YYYY HH:mm:ss')}}
                    </span>
                    </td>
                    <td>
                      <section>
                        <b-button variant="danger" size="sm" style="padding:5px" class="mr-1" 
                          v-if="isChild(item.user.roleUsers)"
                          @click="confirmDelete(item.sys_user_id)">
                          <v-icon style="color:white;padding:0px;" small>mdi-trash-can-outline</v-icon>
                        </b-button>
                        <b-button variant="primary" size="sm" style="padding:5px" class="mr-1"
                          v-if="isChild(item.user.roleUsers)"
                          @click="editUser(item.sys_user_id)">
                            <v-icon style="color:white;padding:0px;" small>mdi-pencil</v-icon>
                        </b-button>
                        <b-button
                          variant="success"
                          style="padding:6px" 
                          size="sm"
                          @click="detailData(item.sys_user_id)"
                        >
                          >>
                        </b-button>
                      </section>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="10"
                aria-controls="my-table"
              ></b-pagination>
            </b-col>
          </b-row>
    </div>
</template>
<style>
#search-card .card-body {
  padding:0px !important;
}
</style>
<script>
import Swal from "sweetalert2";
  import { ModelSelect } from 'vue-search-select'

export default {
  name: "Sengketa",
  components: {
      ModelSelect
  },
  data() {
    return {
      options: [],
      createMode: true,
      currentPage: 1,
      currentPageModal: 1,
      currentOffset: 0,
      totalRows: 0,
      q: {},
      loadingAct: false,
      search: '',
      list: [],
      detailPengguna: '',
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL,
      formPass: { newPassword: '', confirmPassword: ''},
      form: {
        "nama_pengguna": "",
        "nip": "",
        "nik": "",
        "email": "",
        "no_hp": "",
        "instansi": 0,
        "user_type": 0,
        "url_login": process.env.VUE_APP_APPLICATION_BASE_URL + "/polpum",
        "foto": ""
      },
      id: null,
      listOrmas: [],
      filter: {
        status_sengketa: null,
        tanggal_sengketa: ''
      },
      filterStatus: false,
      listRole: [],
      listActivity: [],
      loadUpload: false,
      errAct: false
    }
  },
  mounted(){
    this.fetchData()
    this.getOrmasList()
    this.getRole()
  }, 
  watch:{
    currentPage(to){
      this.currentOffset = to - 1
      this.fetchData()
    },
    currentPageModal(to){
      this.currentOffset = to - 1
      this.fetchData()
    },
    search: {
      handler() {
        // Check if the user has stopped typing
        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
        this.typingTimeout = setTimeout(() => {
          // User has stopped typing, perform your action here
          this.searching()

        }, 500); // Adjust the delay as needed (1 second in this example)
      },
      immediate: false, // Set to true if you want to trigger it immediately
    }
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = "/assets/images/default-thumbnail.jpg"
    },
    showFilter(){
      this.filterStatus = false
      this.$bvModal.show('filter')
    },
    getRole(){
      var nonOrmas = true
      this.$store
        .dispatch("userTypeList", nonOrmas)
        .then((res) => {
          this.listRole = res.data
        })
    },
    searching(){
      this.q.search = this.search.trim()
      this.q.instansi = this.search.trim()

      if (this.q.search.length > 0 && this.q.search.length < 3) return

      this.fetchData()
    },
    fetchData(){
      this.q.offset = this.currentOffset
      this.itemCountGet()
      this.$store
        .dispatch("listUsers", this.q)
        .then((res) => {
          this.list = res.data.data
        })
    },
    itemCountGet() {
      this.$store
        .dispatch("getUsersCount", this.q)
        .then((res) => {
          this.totalRows = res.data.count
      })
    },
    closeForm(){
      this.$bvModal.hide('modal-form')
      this.createMode = true
    },
    endSengketaForm(){
      this.$bvModal.show('end-sengketa')
      this.$bvModal.hide('modal-form')
    },
    batalEndForm(){
      this.$bvModal.hide('end-sengketa')
      this.$bvModal.show('modal-form')
    },
    getOrmasList(){
      this.$store
        .dispatch("instansiList")
        .then((res) => {
           this.$nextTick(() => {
            this.listOrmas = res.data
            for(var i = 0; i < res.data.length; i++){
              this.options.push({value: res.data[i].id , text: res.data[i].nama})
            }
          })
        })
    },
    detailData(id){
      this.errAct = false
      this.$store
      .dispatch("detailUsers", id)
      // .dispatch("getBankAccountOrganization", 4)
      .then((res) => {
        this.detailPengguna = res.data
        this.idnya = id
        this.$bvModal.show('detail-pengguna')
        this.loadingAct = true
        this.$store
        .dispatch("userActivity", id)
        .then((res) => {
          this.loadingAct = false
          this.listActivity = res.data
        }).catch(()=>{
          this.loadingAct = false
          this.errAct = true
        })
      })
    },
    emptyForm(){
      this.form = {
        "nama_pengguna": "",
        "nip": "",
        "nik": "",
        "email": "",
        "no_hp": "",
        "instansi": 0,
        "user_type": 0,
        "url_login": process.env.VUE_APP_APPLICATION_BASE_URL + "/polpum",
        "foto": ""
      }
    },
    addForm(){
      this.emptyForm()
      this.$bvModal.show('modal-form')
      this.createMode = true
    },
    editUser(id){
      this.idnya = id
      this.$store
      .dispatch("detailUsers", id).then((res) => {
        this.form = {
          "nama_pengguna": res.data.profilUser.nama_lengkap,
          "nip": res.data.username,
          "nik": res.data.profilUser.identitas,
          "email": res.data.profilUser.email,
          "no_hp": res.data.profilUser.no_telp,
          "instansi": res.data.profilUser && res.data.profilUser.masterKesbangpol ? res.data.profilUser.masterKesbangpol.id : '',
          "user_type": res.data.roleUsers[0].role.id,
          "url_login": process.env.VUE_APP_APPLICATION_BASE_URL + "/polpum",
          "foto": res.data.profilUser.file_foto
        }
        this.$bvModal.show('modal-form')
        this.createMode = false
      })
    },
    async submit(){
      this.form.user_type = Number(this.form.user_type)
      
      var body = {}
      var path = ''
      if(this.form.instansi == 0 || this.form.instansi == '') delete this.form.instansi
      if(this.createMode) {
        path = 'submitUsers'
        body = this.form
      } else {
        path = 'submitPatchUsers'
        // if(this.form.instansi == '') delete this.form.instansi
        body = [this.form, this.idnya]
      }
        this.$store
        .dispatch(path, body)
        .then((res) => {

          if(!res.data.status) {

            var err = Object.values(res.data.error)
            var er = ''
            for(var i = 0; i < err.length; i++){
              er += err[i] + ','
            }
            er.slice(0,-1)
            Swal.fire(
                {
                  title: 'Gagal Mengubah Data',
                  icon: "warning",
                  text: er,
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  Swal.close();
                }
              );
              return false
          }
          var context = this
          context.$bvModal.hide('modal-form')
          context.fetchData()
          Swal.fire(
                {
                  title: this.createMode ? "Data Berhasil Disimpan" : 'Data Berhasil Diubah',
                  icon: "success",
                  text: "",
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  context.$bvModal.hide('modal-form')
                  context.fetchData()
                  Swal.close();
                }
              );
        })
      
    },
    changePassword(){
      this.formPass = { newPassword: '', confirmPassword: ''}
      this.$bvModal.show('change-password-form')
      
    },
    submitPassword(){
      this.$store
        .dispatch("changePasswordUsers", [this.formPass,this.idnya])
        .then((res) => {
          if(!res.data.status) {

            var err = Object.values(res.data.error)
            var er = ''
            for(var i = 0; i < err.length; i++){
              er += err[i] + ','
            }
            er.slice(0,-1)
            Swal.fire(
                {
                  title: 'Gagal Mengubah Password',
                  icon: "warning",
                  text: er,
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  Swal.close();
                }
              );
              return false
          }
          Swal.fire({
            title: "Password Berhasil Diubah",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then(() => {
            this.$bvModal.hide('change-password-form')
            this.fetchData()
          })
        }).catch(()=>{
          Swal.fire({
            title: "Password Gagal Diubah",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          }).then(() => {
            this.$bvModal.hide('change-password-form')
          })
        })
    },
    deleteData(){
      this.$store
        .dispatch("deleteUsers", this.idnya)
        .then(() => {
          Swal.fire({
            title: "Data berhasil dihapus",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then(() => {
            this.$bvModal.hide('formKonfirmasi')
            this.fetchData()
          })
        }).catch(()=>{
           Swal.fire({
            title: "Data Gagal Dihapus",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          }).then(() => {
            this.$bvModal.hide('formKonfirmasi')
          })
        })
    },
    confirmDelete(id){
      this.idnya = id
      this.$bvModal.show('formKonfirmasi')
    },
    fileClicked(){
        this.$refs.fileInput.click()  
    },
    uploadFile(e){
      this.loadUpload = true
      var calculateSize = Math.floor(e.target.files[0].size / 1024)
      if(calculateSize > 15000) {
        Swal.fire({
          title: "Gagal Upload",
          text: 'Ukuran File Terlalu Besar',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.loadUpload = false
        return false
      }
      
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.$store.dispatch('uploadFileGeneral', formData).then((res) =>{

        if(res.data.status != false){
          this.form.foto = res.data.data.newFilePath,
          
              this.loadUpload = false
              Swal.fire({
                title: "Upload File Berhasil",
                text: '',
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              }).then(() => {
                this.$forceUpdate()
              })
        }
        this.$refs.fileInput.value=null;
      }).catch((err) =>{
        this.loadUpload = false
        Swal.fire({
          title: "Upload File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.$refs.fileInput.value=null;
      })
    },
    isChild(roles) {
      let parents = []
      let myRoles = this.$store.getters.currentUserAccountInfo.roleUsers.map(item => item.role.role_code)

      roles.forEach(val => {
        let role = val.role
        let parent = JSON.parse(role.parent)

        if (parent)
          parents = [...parent]
      });

      // Make it unique
      parents = [...new Set(parents)];

      return parents.some(item => myRoles.includes(item))
    }
  }
}
</script>